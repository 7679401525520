import {Container, Card, Row} from 'react-bootstrap'

function Blog() {
    return (
      <Container>
        <Row>
            <Card></Card>
        </Row>
      </Container>
    );
  }
  export default Blog;
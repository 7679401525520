import React, {Component}  from 'react'
import Navigation from '../components/Navigation';
export class AboutUs extends Component {
    render() {
        return(
            <div>
                <div>
                    <h2>Samar City Developers - About us!</h2>
                </div>
            </div>
        )
    }
}

export default AboutUs;